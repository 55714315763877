@tailwind base;
@tailwind components;

@layer utilities {
    @variants responsive {
        .areas-sidebar {
            grid-area: 1 / 1 / 3 / 2;
        }

        .areas-nav {
            grid-area: 1 / 2 / 2 / 3;
        }

        .areas-main {
            grid-area: 2 / 2 / 3 / 3;
        }

        .max-h-120 {
            max-height: 30rem;
        }
    }
}

@layer components {
    body {
        @apply text-gray-900;
    }

    label {
        @apply text-p-500 font-medium text-sm mb-2;
    }

    input, textarea {
        @apply bg-gray-100 rounded px-3 py-2 font-light leading-6 outline-none text-p-900 w-full;
    }

    p {
        @apply mb-2;
    }

    main.center > * {
        margin: auto;
    }
    
    .btn {
        @apply text-white px-5 py-2 text-sm rounded leading-4 cursor-pointer focus:outline-none;
    }

    .btn-primary {
        @apply btn bg-p-500 border-2 border-p-500 hover:bg-p-800 hover:border-p-800 active:filter active:shadow-inset;
    }

    .btn-secondary {
        @apply btn border-2 border-p-500 font-medium text-p-500 hover:bg-p-500 hover:text-white active:filter active:shadow-inset;
    }

    .btn-tertiary {
        @apply btn font-medium text-p-500 hover:border-2 hover:bg-gray-400 hover:border-gray-400 active:bg-gray-tint-200;
    }

    .error {
        @apply text-error-200 text-xs mt-1 font-medium;
    }

    .grid-cols-max {
        grid-template-columns: max-content;
        grid-auto-flow: column;
    }

    .no-scroll::-webkit-scrollbar {
        display: none;
    }

    .no-scroll {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .no-data {
        @apply text-gray-600;
        @apply text-base;
        @apply leading-7;
        @apply pt-8;
        @apply sm:text-xl;
        @apply sm:leading-8;
        @apply sm:pt-12;
        @apply md:text-2xl;
        @apply md:leading-9;
        @apply md:pt-16;
        @apply lg:text-3xl;
        @apply lg:leading-10;
        @apply lg:pt-20;
        width: 100%;
        text-align: center;
    }

    .no-data a {
        @apply text-p-500;
        @apply hover:text-p-700;
    }
  }

@font-face {
    font-family: "Wotfard";
    src: url("./fonts/Wotfard/wotfard-thin-webfont.woff2") format("woff2");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Wotfard";
    src: url("fonts/Wotfard/wotfard-extralight-webfont.woff2") format("woff2");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Wotfard";
    src: url("fonts/Wotfard/wotfard-light-webfont.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Wotfard";
    src: url("fonts/Wotfard/wotfard-regular-webfont.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Wotfard";
    src: url("fonts/Wotfard/wotfard-medium-webfont.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Wotfard";
    src: url("fonts/Wotfard/wotfard-semibold-webfont.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Wotfard";
    src: url("fonts/Wotfard/wotfard-bold-webfont.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

:root {
    --toggle-height: 20px;
    --toggle-width: 50px;
    --toggle-padding: 3px;
}

.react-toggle {
    padding: 0;
    height: var(--toggle-height);
    width: var(--toggle-width);
}

.react-toggle .react-toggle-track {
    @apply bg-gray-400 !important;
    width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 10px;
}

.react-toggle .react-toggle-thumb {
    border: none;
    @apply bg-p-500;
    height: calc(var(--toggle-height) - 2 * var(--toggle-padding));
    width: calc(var(--toggle-height) - 2 * var(--toggle-padding));
    top: var(--toggle-padding);
    left: var(--toggle-padding);
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25) !important;
}

.react-toggle--checked .react-toggle-thumb {
    left: calc(var(--toggle-width) - (var(--toggle-height) - var(--toggle-padding))) !important;
}
@tailwind utilities;